import React from 'react'
import liff from '@line/liff';
import liffProps from './liffid.json'

import './home.css';

export default class home extends React.Component
{
  constructor( props )
  {

    super( props );
    
    this.init();
  
  }

  init()
  {
    console.log('Home');

    this.textarea = React.createRef();
    this.btnLogin = React.createRef();
    this.profiles = React.createRef();
    this.camera = React.createRef();
    this.cameraResult = React.createRef();

    this.sampleBlock01 = React.createRef();


    this.winopen = React.createRef();
    this.winclose = React.createRef();
  }

  componentDidMount()
  {

    liff.init({
        liffId: liffProps.liffId, // Use own liffId
        //  withLoginOnExternalBrowser: true, // Enable automatic login process

    })
    .then(() => {


      //  LIFFアプリを開発する
      //  https://developers.line.biz/ja/docs/liff/developing-liff-apps/

      //  バックエンドとやり取りするには必須
      //  https://note.com/pro_buncho/n/n41f4ba41df5e#bQ2M9
      //  https://developers.line.biz/ja/docs/liff/developing-liff-apps/#getting-decoded-id-token

      //idトークンによる年齢情報の取得
      /*
      const idToken = liff.getIDToken();
      const jsonData = JSON.stringify({
        id_token: idToken
      });

      fetch('/api',{
        method: 'POST',
        headers: {
          'Content-Type':'application/json'
        },
        body: jsonData,
        creadentials: 'same-origin'
      })
      .then(res=>{
        //ここにレスポンス返ってくる

      })
      .catch(e=>console.log(e));
      */

      //LIFFで立ち上げているかどうかの判定
      if(liff.isInClient()){
        let _text = this.textarea.current;
        _text.value += 'これはLIFF画面です\n\n';

        this.sampleBlock01.current.classList.add('none');


      }else{
        let _text = this.textarea.current;
        _text.value += 'これはLIFF画面じゃありません\n\n';
      }

      // start to use LIFF's api
      // print the environment in which the LIFF app is running
      console.log('liff.getLanguage():', liff.getLanguage() );
      console.log('liff.getVersion():', liff.getVersion() );
      console.log('liff.isInClient():', liff.isInClient() );
      console.log('liff.isLoggedIn():', liff.isLoggedIn() );
      console.log('liff.getOS():', liff.getOS() );
      console.log('liff.getLineVersion():', liff.getLineVersion() );
      console.log('liff.getContext():', liff.getContext() );
      console.log('liff.getAccessToken(): \t' + liff.getAccessToken() );

      let _text = this.textarea.current;
      _text.value += 'liff.getLanguage(): \t' + liff.getLanguage() + '\n';
      _text.value += 'liff.getVersion(): \t' + liff.getVersion() + '\n';
      _text.value += 'liff.isInClient(): \t' + liff.isInClient() + '\n';
      _text.value += 'liff.isLoggedIn(): \t' + liff.isLoggedIn() + '\n';
      _text.value += 'liff.getOS(): \t' + liff.getOS() + '\n';
      _text.value += 'liff.getLineVersion(): \t' + liff.getLineVersion() + '\n';

      _text.value += 'liff.getAccessToken(): \t' + liff.getAccessToken() + '\n';


      const accessToken = liff.getAccessToken();
      if( accessToken )
      {
        fetch('https://api.line.me/oauth2/v2.1/verify?access_token=' + accessToken, {
          method: 'GET',
          headers: {
             "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
          }
        }).then((data) => {
          console.log('Oauth:', data); // `data.json()` の呼び出しで解釈された JSON データ
        });
      };

      //  大事そうな情報
      //  https://developers.line.biz/ja/docs/liff/developing-liff-apps/#getting-user-profile

      // liff.init() についてメモ
      // https://zenn.dev/nbstsh/scraps/2f821d429fab00

      //  LOGIN
      if ( liff.isLoggedIn() )
      {
        let _text = this.textarea.current;
        this.btnLogin.current.textContent = 'LOGOUT';

        this.textarea.current.value += 'liff.getFriendship(): \t' + liff.getFriendship().friendFlag + '\n';
      } else {
        let _text = this.textarea.current;
        this.btnLogin.current.textContent = 'LOGIN';
      }

      this.btnLogin.current.addEventListener('click', ()=>{

        // let _once = 'once' + Math.floor( Math.random() * 1000000 );

        //  https://developers.line.biz/ja/docs/liff/developing-liff-apps/#login-with-line-login
        // login call, only when external browser or LINE's in-app browser is used
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          liff.logout();
          window.location.reload();
        }

        // logout call only when external browse or LINE's in-app browser is used
        // if (liff.isLoggedIn()) {
        //     liff.logout();
        //     window.location.reload();
        // }

      });

      this.camera.current.addEventListener('click', ()=>{
        liff
        .scanCodeV2()
        .then((result) => {
          // result = { value: "" }

          let _c = this.cameraResult.current;
          let _p = document.createElement('p');
          _p.textContent = 'camera.value: ' + result.value;
          _c.appendChild( _p );

        })
        .catch((error) => {
          console.log("error", error);
          let _c = this.cameraResult.current;
          let _p = document.createElement('p');
          _p.textContent = 'Error: ' + error;
          _c.appendChild( _p );
        });
      });

      this.winopen.current.addEventListener('click', ()=>{
        liff.openWindow({
          url: 'https://nulldesign.jp/skynet/',
          external: false    //  true: 外部ブラウザで開く, false: LINE内ブラウザで開く
        });
        // #
      })

      this.winclose.current.addEventListener('click', ()=>{
        liff.closeWindow()
      })

      //  https://enlyt.co.jp/blog/what-is-liff
      //  ユーザープロファイルの取得
      //  ログインしてたら実行
      if ( liff.isLoggedIn() ) {
        liff.getProfile()
        .then( profile => {

          console.log(profile);  // profile all
          console.log(profile.displayName);  // ユーザーの表示名
          console.log(profile.pictureUrl);   // プロフィール画像のURL

          let _text = this.textarea.current;
          for( var i in profile )
          {
            _text.value += 'liff.profile().'+i+': \t' + profile[i] + '\n';
          }

          let _profiles = this.profiles.current;
          if( profile.displayName )
          {
            let _p = document.createElement('p');
            _p.textContent = 'Name: ' + profile.displayName;
            _profiles.appendChild( _p );
          }

          if( profile.statusMessage )
          {
            let _p = document.createElement('p');
            _p.textContent = 'statusMessage: ' + profile.statusMessage;
            _profiles.appendChild( _p );
          }

          if( profile.userId )
          {
            let _p = document.createElement('p');
            _p.textContent = 'UserID: ' + profile.userId;
            _profiles.appendChild( _p );
          }

          if( profile.pictureUrl )
          {
            let _img = new Image();
            _img.src = profile.pictureUrl;
            _profiles.appendChild( _img );
          }

        })
        .catch( error=> {
          console.error(error);
        });
      }
      



      // function getURL()
      // {
      //   //- https://access.line.me/oauth2/v2.1/authorize
      //   //- ?response_type=code
      //   //- &client_id=2005558906
      //   //- &redirect_uri=https%3A%2F%2Flab.nulldesign.jp%2Fliff
      //   //- &state=12345abcde
      //   //- &scope=profile%20openid
      //   //- &nonce=09876xyz

      //   // openWindow call
      //   // liff.openWindow({
      //   //     url: 'https://line.me',
      //   //     external: true,
      //   // });
      // }

    })
    .catch((err) => {
        console.log(err);
    });

  }

  componentWillUnmount(){}

  render()
  {
    return (
      <div className="home">
      <div className="home-inner">
      <h1 className="title">LIFF Training</h1>
      <p className="description">基礎学習と作法の理解、検証等の記録</p>
      <p><img src={`${process.env.PUBLIC_URL}/899212139-kabekinwallpaper_0006_Layer_40-M8VG-1920x1080-MM-100.jpg`} alt="" /></p>
      
      <div className="sampleBlock01" ref={this.sampleBlock01}>
      <h2>SAMPLE(LIFF画面では非表示)</h2>
      <ul>
      <li><a href="https://liff.line.me/2005841561-LAammmkX" target="_blank">LIFF URL</a> ←ここから始めよう</li>
      </ul>
      <a href="https://liff.line.me/2005841561-LAammmkX" target="_blank"><img src={`${process.env.PUBLIC_URL}/685864c944967b2f6d616dbc4de291cc852240ff.png`} alt="" className="halfImg" /></a>
      </div>

      <p>ドキュメント周り</p>
      <ul>
      <li><a href="https://developers.line.biz/ja/docs/liff/overview/" target="_blank">LINE Front-end Framework (LIFF)</a></li>
      <li><a href="https://liff-playground.netlify.app/" target="_blank">LIFF PLAYGROUND</a></li>
      </ul>

      <p>機能確認</p>
      <div className="loginArea01" ref={this.profiles}><button ref={this.btnLogin}>ログインボタン</button></div>
      <div className="cameraArea01" ref={this.cameraResult}><button ref={this.camera}>liff.scanCodeV2()</button></div>

      <div className="windowopen"><button ref={this.winopen}>liff.openWindow()</button></div>
      <div className="windowclose"><button ref={this.winclose}>#liff.closeWindow()</button></div>
      


      <h2>PROPS</h2>
      <textarea ref={this.textarea}></textarea>



      <h2>チャンネルの特性について</h2>
      <ul>
      <li>試したチャンネルは「LINEログイン」。他には「Messaging API」「ブロックチェーンサービス」「LINEミニアプリ」がある。</li>
      <li>LINEログインの方向性としては、LINEアカウントと紐づけてログインの機能が必要になるWebアプリの開発が完結になると言うもの。ただしバックエンド系の機能は別途開発が必要。（LINEの中に固有の情報は保存できない。）</li>
      <li>Messaging APIは公式アカウントを作りユーザーとメッセージのやり取り等ができる。（メッセージ数によってコストが発生）。</li>
      <li>ブロックチェーンサービスは選択できず。</li>
      <li>LINEミニアプリは申請が必要。主に決済サービスの利用が可能となっている。決済には手数料が必要。</li>
      <li>MessagingAPIのメッセージ数、決済サービスの手数料以外はほぼ無料と考えて良さそうな雰囲気。</li>
      </ul>

      <h2>LINEログインの流れ</h2>
      <ol>
      <li>ページにアクセス</li>
      <li>LPに遷移(PCアクセス時にはQRでSPに行くように促す。)</li>
      <li>SPでLIFFのURL叩く（<a href="https://liff.line.me/2005841561-LAammmkX" target="_blank">https://liff.line.me/2005841561-LAammmkX</a>）エンドポイントのURL(<a href="https://liff.nulldesign.jp/" target="_blank">https://liff.nulldesign.jp/</a>)をアプリ内ブラウザ/デフォルトブラウザで開いてもLIFF画面にはならないことを念頭に入れておくこと。</li>
      <li>LIFFの利用許可みたいな画面が出てくる yes / no</li>
      <li>LIFF画面が起動し、体験スタート</li>

      <li>配信時はトークルームのURLからLIFFアプリに遷移。<br />SNSでシェアする時は「?openExternalBrowser=1」を忘れないことと、シェアはXに絞る方が幸せになる。</li>
      <li>SNSのSHARE設計をきちんとすること。前回は綺麗にまわった。広げるのではなく絞る！</li>

      </ol>

      <h2>検討・確認事項</h2>
      <ul>
      <li><s>PCアクセス時の基本フローの確認</s></li>
      <li><s>PC対応の有無</s>このあたり資料化して共有しておく。</li>
      <li><s>LIFFアプリへの直アクセス時ってどうなる？</s></li>
      <li><s>LINEのなかに情報って記録できる?</s> できない。<br />(LINEからデータを引き出すことはできるが、LINEに固有の情報を書き込むAPIが確認できない。 + バックエンド開発が必須。ユーザーIDを軸に情報を記録となると個人情報の扱いに抵触する恐れがあるので要チェックや。<br />
      追記: useridではなくopenidなるものを使う。 <a href="https://note.com/pro_buncho/n/n41f4ba41df5e" target="_blank">LIFFを使ってみる</a></li>
      <li>LIFFアプリのページは1ページずつ登録する必要がある。ただしSPAの場合は1ページの中での処理になるのでその必要はない。（積極的に開発環境でSPAしていくルールで統一すると楽だが。。。GTMめんどくさそうw）</li>

      <li>APIでできることの整理</li>
        <ul>
        <li><a href="https://liff-playground.netlify.app/" target="_blank">LIFF PLAYGROUND</a> にてAPIからできることがまとまっている。</li>
        <li>LINEの中に情報は保持できない（ほぼ確定）。(localStorageで多少回避できるがユーザーの同意なく情報の保持をすることは禁止されている。 <a href="https://developers.line.biz/ja/docs/liff/development-guidelines/#liff-development-rules3" target="_blank">LIFFアプリを開発する際に必ず守るべきこと</a> )</li>
        </ul>
      </ul>


      <h2>枠組みの整理</h2>
      <ul>
      <li>プロバイダとチャンネルの話の整理が必要。チャンネルはアプリでプロバイダはサービス提供元というイメージ。<br />
      両方とも開発会社が作るのではなく、プロバイダ（発注元/企業側）がチャンネル（アプリのライセンス）を作って、開発会社に支給するイメージ。で合ってるのかな？前回どうやったのんだろう？</li>
      </ul>

      <p>シンプルに会員作って配信とか強いよね。LINE.LOGIN を使ったアプリは。UserIDに紐づけてそのままバックエンドでアカウント管理すればKOな状態。</p>


      <h2>LINEとXってどのタイミングの相性悪かったか改めて把握しておくこと。</h2>
      <p className="caution">↑ Twitterのリンクから https://liff.line.me/xxxxxx を叩くと、LINEで開かずにTwitterのアプリ内ブラウザで開くので問題って話。ワンクッション置いてLPから開かせるとアプリ内ブラウザからでも遷移する。</p>
      <p className="caution">↑ って話がLINEのトークルームのリンク周りでもあるのかな？なので openExternalBrowser=1 のパラメータで回避してデフォルトブラウザで開かせていたと思う。</p>
      <p className="caution">↑ SNSはXに絞って拡散。体験はLP+LIFFに集中させることで、各SNS（INSTA,FaceBook等ひどい時にはGoogleやYahooのアプリまでみて突かれる・・・）アプリ内の個別対応などを気にせずに済むようにする。ユーザーが勝手にコピペ〜して広めたリンクについては対応範囲に含めない。</p>
      <p><a href="https://twitter.com/intent/tweet?text=share%20message%20test. https://liff.nulldesign.jp/" target="_blank">X SHARE</a></p>
      <p><a href="https://twitter.com/intent/tweet?url=https://liff.nulldesign.jp/&text=share%20message%20test.&via=nulldesign&hashtags=hoge,fuga,test" target="_blank">Tweet</a></p>
  
  
      <p className="copyright">&copy; hrsk.</p>
      
      </div>
      </div>
    )
  }
}
